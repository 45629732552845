/* Make sure we remove default margins/padding on html/body */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* or your preferred color */
  }
  
  /* 
    The actual #root from index.html
    This can simply fill the page; no special styling needed 
  */
  #root {
    width: 100%;
    height: 100%;
  }
  
  /* 
    Our container (the one returned by App.js).
    Using CSS Grid to center items both horizontally & vertically.
  */
  .container {
    display: grid;
    place-items: center;
    width: 100vw;      /* Fill viewport width */
    height: 100vh;     /* Fill viewport height */
  }
  
  /* Responsive image styling */
  .logo {
    max-width: 50%;
    max-height: 50%;
    object-fit: contain;
  }
  
  /* Tablet adjustments (iPad, etc.) */
  @media (max-width: 1024px) {
    .logo {
      max-width: 60%;
      max-height: 40%;
    }
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .logo {
      max-width: 70%;
      max-height: 35%;
    }
  }
  